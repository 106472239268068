import React from "react";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import chakraTheme from "./chakraTheme";

// fonts
import "@fontsource/creepster";
import "@fontsource/antic-slab";
import "@fontsource/epilogue";

const Theme: React.FC = ({ children }) => {
  return (
    <ChakraProvider theme={chakraTheme}>
      <CSSReset />
      {children}
    </ChakraProvider>
  );
};

export default Theme;
