// chakra
import { Button, Stack, Text, Flex, Heading, Link } from "@chakra-ui/react";
// ui
import { PageBackButton } from "components/atoms";
import { PageLayout } from "components/layouts";

type SceneProps = {
  goToStep: (step: number) => any;
};

export const Eight: React.FC<SceneProps> = ({ goToStep }) => {
  return (
    <>
      <PageLayout scene="8">
        <PageBackButton onClick={() => goToStep(1)} />
        <Flex
          alignItems="center"
          flexDir="column"
          pr={{ base: "4", lg: "4" }}
          pl="4"
          pt={{ base: "12", lg: "20" }}
          color="white"
          maxW="685px"
          mx="auto"
          textAlign={{ base: "left", lg: "center" }}
        >
          <Heading fontWeight="400" fontSize={{ base: "5xl", md: "6xl", lg: "60px" }}>
            Once your zombie wanders over the bridge...
          </Heading>
          <Text
            fontFamily="display"
            fontSize={{ base: "2xl", lg: "2xl" }}
            mt={{ base: "4px", lg: "10x" }}
          >
            See your Zombie’s unique traits and earn attention rewards.
          </Text>
          <Text fontSize="14px" mt="9px">
            When your zombie lives on Arweave it advances and earns with all the attention it
            receives. Download Finnie and come back to this page so your zombie can come to life.
          </Text>
          <Stack
            spacing="4"
            direction={{ base: "column", lg: "row" }}
            mt="22px"
            w="100%"
            maxW="240px"
          >
            <Button
              colorScheme="purple"
              size="lg"
              w="240px"
              fontSize="16px"
              as={Link}
              isExternal
              href="https://chrome.google.com/webstore/detail/finnie/cjmkndjhnagcfbpiemnkdpomccnjblmj"
              onClick={() => goToStep(3)}
            >
              GET FINNIE
            </Button>
          </Stack>
        </Flex>
      </PageLayout>
    </>
  );
};
