import React from "react";

// context
import { useZombie } from "components/contexts/zombie";
// chakra
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  ModalCloseButton,
  Text,
  SimpleGrid,
  Button,
  Box,
  useToast,
  Image,
} from "@chakra-ui/react";
// api
import { getAddress, handleBuyEthereumCall, lockETHNFT } from "shared/api";
// assets
import demoGif from "assets/images/demo.gif";

type ConfirmBuyEthModalProps = {
  isOpen: boolean;
  onClose: any;
  selectedToBuy: any;
};

export const ConfirmBuyEthModal: React.FC<ConfirmBuyEthModalProps> = ({
  isOpen,
  onClose,
  selectedToBuy,
}) => {
  /* Hooks */
  const toast = useToast();

  /* Context */
  const { dispatch }: { dispatch: (args: any) => void } = useZombie();

  /* States */
  const [isLoading, setIsLoading] = React.useState(false);

  const doBuyZombie = async () => {
    setIsLoading(true);
    await handleBuyEthereumCall(selectedToBuy)
      .then(async res => {
        toast({
          status: "success",
          variant: "top-accent",
          title: "Zombie Purchased",
          isClosable: true,
        });
        /* Lock nft */
        await lockETHNFT(selectedToBuy);
        /* Check if we have finnie */
        await getAddress()
          .then(addressRes => {
            if (addressRes.status === 200) {
              /* We're connected to finnie, raise */
              dispatch({
                type: "CHANGE_FIELDS",
                payload: {
                  address: addressRes?.data,
                  step: "raise",
                },
              });
            } else {
              /* We're not connected, go to step 3 */
              dispatch({
                type: "CHANGE_FIELDS",
                payload: {
                  step: 3,
                },
              });
            }
          })
          .catch(() => {
            /* We're not connected, go to step 3 */
            dispatch({
              type: "CHANGE_FIELDS",
              payload: {
                step: 3,
              },
            });
          });
      })
      .catch(err => {
        if (err?.message === "nft_sold") {
          toast({
            status: "error",
            title: "This NFT is already sold",
            isClosable: true,
          });
        } else {
          toast({
            status: "error",
            title: "Error occurred while buying your zombie!",
            isClosable: true,
          });
        }

        setIsLoading(false);
      });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      size="lg"
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent
        bg="linear-gradient(180deg, rgba(84, 45, 123, 0.8) 0%, rgba(39, 18, 33, 0.8) 100%)"
        backdropFilter="blur(1px)"
        border="2px solid #FFFFFF"
        mx="2"
        transform={{ base: "scale(1)", "2xl": "scale(1.1)!important" }}
      >
        {/* Close Modal */}
        <ModalCloseButton
          color="white"
          rounded="999px"
          boxSize="24px"
          fontSize="15px"
          top="3"
          right="3"
        />

        <ModalBody py={{ base: "4", lg: "6" }} px={{ base: "4", lg: "16" }} color="white">
          <Stack w="100%" spacing="25px" align="center">
            <Text fontSize="30px" fontWeight="600" textAlign="center" textTransform="uppercase">
              Buy a Zombie
            </Text>
            <SimpleGrid columns={2} gap={{ base: "4", lg: "25px" }} w="100%" textAlign="left">
              <Box boxSize="152px">
                <Image src={demoGif} w="100%" h="100%" alt="demo-zombie" />
              </Box>
              <div>
                <Text fontSize="25px">0.03 ETH</Text>
                <Text fontSize="20px" mt="2px" color="green.500">
                  Cost
                </Text>
              </div>
            </SimpleGrid>
            <Button
              w="240px"
              colorScheme="purple"
              bg="transparent"
              size="lg"
              fontSize="16px"
              onClick={doBuyZombie}
              isLoading={isLoading}
            >
              Confirm Purchase
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
