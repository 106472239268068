import React from "react";
import { Link as RouterLink } from "react-router-dom";
// chakra
import {
  Button,
  Stack,
  Text,
  IconButton,
  Link,
  Image,
  SimpleGrid,
  Wrap,
  WrapItem,
  Center,
  Spinner,
} from "@chakra-ui/react";
// ui

import { PageLayout } from "components/layouts";
import { NftBox, PageBackButton } from "components/atoms";

// icons
import { RiExternalLinkLine } from "react-icons/ri";

// mocks + apis
import { getSoldOpenseaAssets } from "shared/api";

type SceneProps = {
  goToStep: (step: any) => any;
};

export const Graveyard: React.FC<SceneProps> = ({ goToStep }) => {
  /* Hooks */

  const [{ nfts, nftsView, status }, setNfts] = React.useState<any>({
    nfts: [],
    status: "loading",
  });

  const [selected, setSelected] = React.useState<any>();

  const selectedNft = nfts?.filter((n: any) => n.token_id === selected)?.[0];

  const getNfts = async () => {
    let soldNfts: any = [];
    try {
      /* Get Sold Nfts on Opensea */
      await getSoldOpenseaAssets().then(res => {
        soldNfts = res?.data?.assets;
      });
      setNfts((prevState: any) => ({
        ...prevState,
        nfts: soldNfts,
        nftsView: soldNfts,
        status: "success",
      }));
      setSelected(soldNfts?.[0]?.token_id);
    } catch (err) {
      setNfts((prevState: any) => ({ ...prevState, status: "error" }));
    }
  };

  React.useEffect(() => {
    /* Get Nfts on mount */
    getNfts();
  }, []);

  return (
    <>
      <PageLayout
        scene="3"
        h={{ base: "unset", lg: "100vh" }}
        minH={{ base: "100vh", lg: "unset" }}
      >
        <PageBackButton as={RouterLink} to="/" />
        {status !== "success" && (
          <Center pt="5" w="100vw" h="100vh">
            <Stack
              px="4"
              py="4"
              flexDir="column"
              maxW="300px"
              bg="purple.500"
              color="white"
              rounded="md"
              w="100%"
              mx="auto"
              alignItems="center"
              textAlign="center"
            >
              <Text fontSize="lg" fontWeight="500">
                {status === "loading"
                  ? "Getting zombies..."
                  : "There aren't any Atomic Zombies yet"}
              </Text>
              {status === "loading" && (
                <Spinner
                  size="lg"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="violet.100"
                  color="white"
                />
              )}
            </Stack>
          </Center>
        )}
        {status === "success" && (
          <SimpleGrid
            maxW="1150px"
            w="100%"
            mx="auto"
            columns={{ base: 1, lg: 2 }}
            px={{ base: "4", lg: "8" }}
            py="20"
            color="white"
          >
            {/* Nfts Grid */}
            <Wrap
              order={{ base: 2, lg: 1 }}
              w="100%"
              maxW={{ base: "350px", lg: "500px" }}
              mx="auto"
              columns={{ base: 2, lg: 3 }}
              overflowY="auto"
              maxH={{ base: "400px", lg: "605px" }}
              spacing="2"
              p="4px"
              justify={{ base: "center", lg: "flex-start" }}
              className="custom-scroll"
            >
              {nftsView?.map((nft: any) => (
                <WrapItem key={nft.id}>
                  <NftBox
                    onClick={() => setSelected(nft.token_id)}
                    isActive={selected === nft.token_id}
                    currentNft={nft}
                  />
                </WrapItem>
              ))}
            </Wrap>

            {/* Selection */}
            <Stack
              order={{ base: 1, lg: 2 }}
              w="100%"
              spacing="4"
              maxW="453px"
              mx="auto"
              px={{ base: "0", lg: "4" }}
              pb={{ base: "4", lg: "0" }}
            >
              <Center w="100%" h={{ base: "300px", lg: "500px" }}>
                {/* Selected Nft Image */}
                <Image src={selectedNft?.image_url} mx="auto" maxH="100%" />
              </Center>
              {/* Selected Nft Details */}

              <Stack spacing="1">
                <Stack direction="row" align="center">
                  <Text
                    fontWeight="600"
                    fontSize="lg"
                    noOfLines={1}
                    textShadow="1px 1px 2px rgba(0, 0, 0, 1)"
                  >
                    {selectedNft?.name}
                  </Text>
                  {selectedNft && (
                    <IconButton
                      size="sm"
                      variant="ghost"
                      colorScheme="violet"
                      border="none"
                      color="white"
                      as={Link}
                      href={selectedNft?.permalink}
                      isExternal
                      aria-label="open link"
                      icon={<RiExternalLinkLine size="20px" />}
                    />
                  )}
                </Stack>
                <Text noOfLines={2} fontSize="sm" textShadow="1px 1px 2px rgba(0, 0, 0, 1)">
                  {selectedNft?.description}
                </Text>
              </Stack>
            </Stack>
          </SimpleGrid>
        )}
      </PageLayout>
    </>
  );
};
