import React from "react";
/* Context */
import { useZombie } from "components/contexts/zombie";
// chakra
import { Stack, Tooltip, useDisclosure, IconButton } from "@chakra-ui/react";
// ui
import { PageDrawer } from "components/atoms";
// assets
import { ReactComponent as Finnie } from "assets/images/brand/blue-logo.svg";
import { ReactComponent as MetaMask } from "assets/images/brand/metamask.svg";
// icons
import { RiMenu3Fill } from "react-icons/ri";

type State = {
  ethAddress: any;
  address: any;
};

export function PageToolbar() {
  /* Context */
  const {
    state: { ethAddress, address },
    dispatch,
  }: { state: State; dispatch: (args: any) => void } = useZombie();

  function goToStep(step: any) {
    dispatch({ type: "GO_TO_STEP", payload: step });
  }

  /* Hooks */

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* Drawer */}
      <PageDrawer goToStep={goToStep} isOpen={isOpen} onClose={onClose} />
      <Stack
        direction="row"
        pos="absolute"
        right={{ base: "10px", lg: "20px" }}
        top={{ base: "10px", lg: "20px" }}
      >
        {ethAddress && (
          <Tooltip
            p="2"
            bg="purple.500"
            hasArrow
            maxW="183px"
            textAlign="center"
            placement="bottom-end"
            arrowShadowColor="white"
            fontSize="14px"
            label="Your Metamask wallet is connected."
          >
            <MetaMask title="finnie" width="24px" height="24px" />
          </Tooltip>
        )}

        {address && (
          <Tooltip
            p="2"
            bg="purple.500"
            hasArrow
            maxW="163px"
            textAlign="center"
            placement="bottom-end"
            arrowShadowColor="white"
            fontSize="14px"
            label="Your Finnie wallet is connected."
          >
            <Finnie title="finnie" width="24px" height="24px" />
          </Tooltip>
        )}

        <IconButton
          pt="0"
          boxSize="25px"
          variant="ghost"
          bg="transparent"
          size="sm"
          _hover={{ bg: "transparent" }}
          _focus={{ bg: "transparent" }}
          _active={{ bg: "transparent" }}
          border="none"
          color="white"
          aria-label="open menu"
          icon={<RiMenu3Fill size="18px" />}
          onClick={onOpen}
        />
      </Stack>
    </>
  );
}
