import React from "react";
// chakra
import { Box, Link } from "@chakra-ui/react";

const AppLayout: React.FC = ({ children }) => {
  return (
    <Box as="main" minH="100vh" overflowX="hidden" w="100%" pos="relative" bg="black">
      <Link
        href="https://koii.network/"
        isExternal
        textDecor="underline"
        color="white"
        fontSize="xs"
        pos="absolute"
        right="18px"
        top="18px"
        zIndex="999"
      ></Link>
      {children}
    </Box>
  );
};

export default AppLayout;
