import React from "react";

// context
import { useZombie } from "components/contexts/zombie";
// chakra
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Stack,
  ModalCloseButton,
  Text,
  SimpleGrid,
  Button,
  Box,
  useToast,
  Image,
} from "@chakra-ui/react";
// api
import { buyNft, interactWrite } from "shared/api";
// assets
import demoGif from "assets/images/demo.gif";

// sdk
import * as kweb from "@_koi/sdk/web";

const koiSDK = new kweb.Web();

type ConfirmModalProps = {
  isOpen: boolean;
  onClose: any;
};

type State = {
  randomId: any;
  address: any;
};

export const ConfirmModal: React.FC<ConfirmModalProps> = ({ isOpen, onClose }) => {
  /* Hooks */
  const toast = useToast();

  /* Context */
  const {
    state: { randomId, address },
    dispatch,
  }: { state: State; dispatch: (args: any) => void } = useZombie();

  /* States */
  const [isLoading, setIsLoading] = React.useState(false);

  async function doBuyZombie() {
    /* Buy with Koii */
    try {
      let transferTx: any;
      setIsLoading(true);
      /* 3: Send 15 Koii */
      const adminWallet = "iLybsW8pdtNycJkF3F6Q2EsdRwrx03lXpxKebKo45CQ";

      const input = {
        function: "transfer",
        qty: 15,
        target: adminWallet,
        nftId: randomId,
      };
      const koiContract = koiSDK.contractId;
      await interactWrite(input, koiContract).then(res => {
        transferTx = res;
      });
      /* Buy the nft */
      await buyNft(randomId, address, transferTx)
        .then(res => {
          dispatch({
            type: "CHANGE_FIELDS",
            payload: {
              step: 6,
              nftType: null,
              selectedToRaise: null,
              transferTx,
            },
          });
        })
        .catch(error => {
          toast({
            status: "error",
            title: error?.response?.data?.message,
            isClosable: true,
          });
          throw new Error("none");
        });
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
      if (error?.message !== "none") {
        toast({
          status: "error",
          title: "Error occurred while buying your zombie!",
          isClosable: true,
        });
      }
    }
  }
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInBottom"
      size="lg"
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent
        bg="linear-gradient(180deg, rgba(84, 45, 123, 0.8) 0%, rgba(39, 18, 33, 0.8) 100%)"
        backdropFilter="blur(1px)"
        border="2px solid #FFFFFF"
        mx="2"
        transform={{ base: "scale(1)", "2xl": "scale(1.1)!important" }}
      >
        {/* Close Modal */}
        <ModalCloseButton
          color="white"
          rounded="999px"
          boxSize="24px"
          fontSize="15px"
          top="3"
          right="3"
        />

        <ModalBody py={{ base: "4", lg: "6" }} px={{ base: "4", lg: "16" }} color="white">
          <Stack w="100%" spacing="25px" align="center">
            <Text fontSize="30px" fontWeight="600" textAlign="center" textTransform="uppercase">
              Buy a Zombie
            </Text>
            <SimpleGrid columns={2} gap={{ base: "4", lg: "25px" }} w="100%" textAlign="left">
              <Box boxSize="152px">
                <Image src={demoGif} w="100%" h="100%" alt="demo-zombie" />
              </Box>
              <div>
                <Text fontSize="25px">15 KOII</Text>
                <Text fontSize="20px" mt="2px" color="green.500">
                  Cost
                </Text>
                <Text fontSize="24px" mt="11px">
                  ~0.00000137 AR
                </Text>
                <Text fontSize="20px" mt="2px" color="green.500">
                  Transaction Fee
                </Text>
              </div>
            </SimpleGrid>
            <Button
              w="240px"
              colorScheme="purple"
              bg="transparent"
              size="lg"
              fontSize="16px"
              onClick={doBuyZombie}
              isLoading={isLoading}
            >
              Confirm Purchase
            </Button>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
