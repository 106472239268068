import React from "react";
// chakra
import { Button, Text, Image, Center, Skeleton, Link } from "@chakra-ui/react";

// utils
import { zeroPad } from "shared/utils";
// api
// import { generateCardWithData } from "shared/api";

type NftLeaderboardBoxProps = {
  onClick: () => void;
  isActive: boolean;
  currentNft: any;
  index: number;
};

export const NftLeaderboardBox: React.FC<NftLeaderboardBoxProps> = React.memo(
  ({ onClick, isActive, currentNft, index }) => {
    const id = currentNft?.id;
    let formattedName;
    if (currentNft?.name) {
      let formattedText = currentNft?.name?.replaceAll?.("Atomic", "");
      formattedText = formattedText?.replaceAll?.("Zombie", "");
      formattedName = formattedText || id;
    } else {
      formattedName = `#${zeroPad(id)}`;
    }

    const [isError, setIsError] = React.useState<boolean>(false);
    // const [isLoading, setIsLoading] = React.useState<boolean>(false);

    /* Get thumbnail */

    // async function doGenerateCardWithData() {
    //   setIsLoading(true);
    //   await generateCardWithData(currentNft)
    //     .then(() => {
    //       setIsLoading(false);
    //     })
    //     .catch(() => {
    //       setIsLoading(false);
    //     });
    // }

    // React.useEffect(() => {
    //   doGenerateCardWithData();
    //   // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);
    return (
      <Button
        w="100px"
        h="150px"
        variant="unstyled"
        mx="auto"
        p="0"
        m="0"
        boxShadow={isActive ? "outline" : "none"}
        _hover={{ boxShadow: "outline", zIndex: 2222 }}
        border="none"
        outline="none"
        onClick={onClick}
        pos="relative"
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Index */}
        <Text
          pos="absolute"
          fontSize="12px"
          top="5px"
          left="5px"
          textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)"
        >
          {index + 1}
        </Text>
        {isError ? (
          <Skeleton
            startColor="violet.500"
            endColor="violet.400"
            speed={2}
            roundedTop="sm"
            maxH="100px"
            w="100%"
            flexBasis="100px"
            mx="auto"
          />
        ) : (
          <Image
            border="1px solid white"
            roundedTop="sm"
            src={`https://koii.live/${id}.png`}
            maxH="100px"
            mx="auto"
            flexBasis="100px"
            onError={() => setIsError(true)}
          />
        )}
        {/* Details */}
        <Center
          flexDir="column"
          w="100%"
          pt="3"
          roundedBottom="sm"
          textAlign="center"
          flexBasis="50px"
          maxW="145px"
          overflow="hidden"
          textTransform="none"
        >
          <Text fontSize="12px" noOfLines={1} maxW="125px" whiteSpace="pre-line">
            {formattedName}
          </Text>
          <Text fontSize="12px" color="#86D180">
            {currentNft?.views} views
          </Text>
        </Center>
      </Button>
    );
  },
  (prev: any, next: any) => {
    /* Only re-render the ones whose isActive changed */
    const idEqual = prev.isActive === next.isActive;
    return idEqual;
  }
);
