import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Stack,
  Heading,
  Box,
  IconButton,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightAddon,
  Input,
  useClipboard,
} from "@chakra-ui/react";
// icons
import { VscArrowLeft } from "react-icons/vsc";
import { RiTwitterLine, RiLinkedinLine } from "react-icons/ri";
import { FiFacebook, FiMail } from "react-icons/fi";

type ShareModalProps = {
  isOpen: boolean;
  onClose: any;
  id: any;
};

const ShareModal: React.FC<ShareModalProps> = ({ id, isOpen, onClose }) => {
  const permalink = `https://koii.live/${id}/.html`;
  const embedLink = `<iframe width="100%" src="${permalink}" title="Koii  NFT image" frameborder="0" allowfullscreen></iframe>`;

  const { hasCopied: hasPermainkCopied, onCopy: onPermalinkCopy } = useClipboard(permalink);
  const { hasCopied: hasEmbedLinkCopied, onCopy: onEmbedLinkCopy } = useClipboard(embedLink);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnEsc={false}
      closeOnOverlayClick={false}
      motionPreset="slideInBottom"
      size="3xl"
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        bg="linear-gradient(180deg, rgba(84, 45, 123, 0.8) 0%, rgba(39, 18, 33, 0.8) 100%)"
        backdropFilter="blur(10px)"
        border="2px solid #FFFFFF"
        mx="2"
        transform={{ base: "scale(1)", "2xl": "scale(1.1)!important" }}
      >
        {/* Go Prev Button */}

        <IconButton
          aria-label="go-back"
          variant="unstyled"
          icon={<VscArrowLeft size="14px" />}
          boxSize="24px"
          minW="24px"
          color="white"
          rounded="999px"
          border="1px solid #fff"
          pos="absolute"
          top="3"
          left="3"
          onClick={onClose}
          d="flex"
          alignContent="center"
        />

        {/* Close Modal */}
        <ModalCloseButton
          color="white"
          rounded="999px"
          border="1px solid #fff"
          boxSize="24px"
          fontSize="9px"
          top="3"
          right="3"
        />

        <ModalBody py={{ base: "10", lg: "8" }} pl="8" pr="8" color="white">
          <Stack w="100%" align="center" textAlign="center">
            <Heading
              fontSize={{ base: "22px", lg: "30px" }}
              as="h2"
              textAlign="center"
              mb={{ base: "2", lg: "5" }}
              fontFamily="body"
              textTransform="uppercase"
            >
              Spread the Contagion
            </Heading>

            <Stack w="100%" direction={{ base: "column", lg: "row" }} spacing="8">
              <Box
                maxW="184px"
                w="100%"
                h="184px"
                mx="auto"
                shadow="0px 0px 24px 6px rgba(255, 255, 255, 0.25)"
              >
                <Box
                  as="iframe"
                  title="nft-iframe"
                  src={`https://arweave.net/${id}`}
                  w="100%"
                  h="100%"
                />
              </Box>
              <Stack w="100%" alignItems="flex-start">
                {/* Inputs */}
                <Stack spacing="6" w="100%">
                  {/* Share */}
                  <FormControl>
                    <FormLabel htmlFor="share">Share:</FormLabel>
                    <InputGroup>
                      <Input name="share" id="share" isReadOnly value={permalink} />
                      <Box pos="relative">
                        <InputRightAddon
                          w="135px"
                          d="flex"
                          alignItems="center"
                          justifyContent="center"
                          cursor="pointer"
                          textAlign="center"
                          color={hasPermainkCopied ? "purple.500" : "black"}
                          children={hasPermainkCopied ? "Copied" : "Get share link"}
                          onClick={onPermalinkCopy}
                        />
                      </Box>
                    </InputGroup>
                  </FormControl>
                  {/* Embed */}
                  <FormControl>
                    <FormLabel htmlFor="embed">Embed:</FormLabel>
                    <InputGroup>
                      <Input name="embed" id="embed" isReadOnly value={embedLink} />
                      <Box pos="relative">
                        <InputRightAddon
                          w="135px"
                          d="flex"
                          alignItems="center"
                          justifyContent="center"
                          cursor="pointer"
                          color={hasEmbedLinkCopied ? "purple.500" : "black"}
                          children={hasEmbedLinkCopied ? "Copied" : "Get embed link"}
                          onClick={onEmbedLinkCopy}
                        />
                      </Box>
                    </InputGroup>
                  </FormControl>
                </Stack>

                <Stack
                  w="100%"
                  justify="center"
                  spacing={{ base: "10", lg: "10" }}
                  direction="row"
                  pt={{ base: "4", lg: "8" }}
                >
                  {/* Social Share */}
                  <IconButton
                    as="a"
                    target="_blank"
                    href={`https://twitter.com/share?url=${permalink}`}
                    aria-label="share-to-twitter"
                    size="lg"
                    colorScheme="violet"
                    bg="transparent"
                    border="none"
                    icon={<RiTwitterLine size="28px" />}
                    rounded="full"
                  />

                  <IconButton
                    as="a"
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${permalink}`}
                    aria-label="share-to-facebook"
                    size="lg"
                    colorScheme="violet"
                    bg="transparent"
                    border="none"
                    icon={<FiFacebook size="28px" />}
                    rounded="full"
                  />

                  <IconButton
                    as="a"
                    target="_blank"
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=${permalink}`}
                    aria-label="share-to-linkedin"
                    size="lg"
                    colorScheme="violet"
                    bg="transparent"
                    border="none"
                    icon={<RiLinkedinLine size="28px" />}
                    rounded="full"
                  />
                  <IconButton
                    as="a"
                    href={`mailto:?subject=I want to share this with you ${permalink}`}
                    aria-label="share-to-email"
                    size="lg"
                    colorScheme="violet"
                    bg="transparent"
                    border="none"
                    icon={<FiMail size="28px" />}
                    rounded="full"
                  />
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
