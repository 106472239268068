// chakra
import { IconButton } from "@chakra-ui/react";
// icons
import { VscArrowLeft } from "react-icons/vsc";

export function PageBackButton({ ...restProps }) {
  return (
    <IconButton
      aria-label="go-back"
      variant="unstyled"
      fontSize={{ base: "20px", lg: "30px" }}
      icon={<VscArrowLeft />}
      boxSize={{ base: "35px", lg: "45px" }}
      minW="24px"
      color="white"
      rounded="999px"
      border="2px solid #fff"
      pos="absolute"
      top={{ base: "10px", lg: "17px" }}
      left={{ base: "10px", lg: "17px" }}
      d="flex"
      alignContent="center"
      {...restProps}
    />
  );
}
