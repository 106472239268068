import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";

// layouts
import { AppLayout } from "components/layouts";
// routes
import { PublicRoute } from "pages/common";
// pages
import { Home, Graveyard, Leaderboard } from "pages";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute exact path="/" component={Home} layout={AppLayout} />
        <PublicRoute exact path="/graveyard" component={Graveyard} layout={AppLayout} />
        <PublicRoute exact path="/leaderboard" component={Leaderboard} layout={AppLayout} />
      </Switch>
    </BrowserRouter>
  );
}
