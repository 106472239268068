import React from "react";
// chakra
import { Button, Text, Image, Center } from "@chakra-ui/react";

// assets
import demoGif from "assets/images/demo.gif";

import { zeroPad } from "shared/utils";

type NftBoxProps = {
  onClick: () => void;
  isActive: boolean;
  currentNft: any;
};

export const NftBox: React.FC<NftBoxProps> = ({ onClick, isActive, currentNft }) => {
  const id = currentNft?.tokenId || currentNft?.token_id;
  let formattedName;
  if (currentNft?.name) {
    let formattedText = currentNft?.name?.replaceAll?.("Atomic", "");
    formattedText = formattedText?.replaceAll?.("Zombie", "");
    formattedName = formattedText || id;
  } else {
    formattedName = `#${zeroPad(id)}`;
  }

  return (
    <Button
      w="145px"
      h="180px"
      variant="unstyled"
      p="0"
      m="0"
      boxShadow={isActive ? "outline" : "none"}
      _hover={{ boxShadow: "outline", zIndex: 2222 }}
      border="none"
      outline="none"
      onClick={onClick}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Image
        roundedTop="sm"
        src={currentNft?.image_preview_url || demoGif}
        maxH="145px"
        mx="auto"
        flexBasis="145px"
      />
      {/* Details */}
      <Center
        flexDir="column"
        alignItems="flex-start"
        w="100%"
        p="2"
        roundedBottom="sm"
        bg="purple.500"
        textAlign="left"
        flexBasis="35px"
        maxW="145px"
        overflow="hidden"
      >
        <Text fontSize="12px" noOfLines={1} maxW="125px" whiteSpace="pre-line">
          {formattedName}
        </Text>
      </Center>
    </Button>
  );
};
