import { extendTheme } from "@chakra-ui/react";

// Let's say you want to add custom colors
const chakraTheme = extendTheme({
  // Colors
  colors: {
    white: {
      500: "#fff",
    },
    purple: {
      "50": "#E58AE7",
      "100": "#E075E2",
      "200": "#D64BD9",
      "300": "#C42AC7",
      "400": "#9A219D",
      "500": "#711873",
      "600": "#58135A",
      "700": "#3F0D40",
      "800": "#260827",
      "900": "#0D030E",
    },
    violet: {
      "50": "#C7ADE1",
      "100": "#BA9ADA",
      "200": "#A075CC",
      "300": "#874FBF",
      "400": "#6D3BA0",
      "500": "#542D7B",
      "600": "#422361",
      "700": "#301A47",
      "800": "#1E102D",
      "900": "#0D0712",
    },
    green: {
      "50": "#F4FBF3",
      "100": "#E7F6E5",
      "200": "#CEEDCB",
      "300": "#B6E4B0",
      "400": "#9DDB96",
      "500": "#84D27B",
      "600": "#61C555",
      "700": "#46AC3B",
      "800": "#37862E",
      "900": "#276021",
    },
  },
  fonts: {
    body: "Epilogue, sans-serif",
    display: "Antic Slab, sans-serif",
    heading: "Creepster, sans-serif",
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: "3px",
        borderStyle: "solid",
        borderColor: "white",
        borderWidth: "1px",
        textTransform: "uppercase",
        lineHeight: "16px",
        letterSpacing: "0.015em",

        _loading: {
          opacity: 1,
        },
      },
      variants: {
        white: {
          bg: "white",
          color: "black",
        },
      },
    },
    // Form
    Input: {
      variants: {
        outline: {
          field: {
            borderRadius: "2px",
            borderWidth: "1px",
            borderColor: "white",
            _hover: { borderColor: "white" },
            _focus: { borderColor: "white" },
            _invalid: { boxShadow: "none" },
          },
        },
      },
    },
    Tooltip: {
      baseStyle: {
        border: "1px solid #FFFFFF",
      },
      defaultProps: {
        hasArrow: true,
      },
    },
  },
});
export default chakraTheme;
