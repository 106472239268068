import React from "react";
// chakra
import {
  Button,
  Stack,
  Text,
  IconButton,
  Link,
  Image,
  SimpleGrid,
  useToast,
  Wrap,
  WrapItem,
  Center,
  Spinner,
} from "@chakra-ui/react";
// ui
import { useZombie } from "components/contexts/zombie";
import { PageLayout } from "components/layouts";
import { NftBox, PageBackButton } from "components/atoms";
// icons
import { RiExternalLinkLine } from "react-icons/ri";
// assets
import demoGif from "assets/images/demo.gif";
// mocks + apis
import { getOpenseaAssets, handleEthereumCall } from "shared/api";

type SceneProps = {
  goToStep: (step: any) => any;
};

type State = {
  address: any;
  ethAddress: any;
  zombiesNfts: Array<{ [key: string]: any }>;
};

export const Raise: React.FC<SceneProps> = ({ goToStep }) => {
  const toast = useToast();

  const {
    state: { zombiesNfts, ethAddress, address },
    dispatch,
  }: { state: State; dispatch: (args: any) => void } = useZombie();

  const [selected, setSelected] = React.useState<any>(zombiesNfts?.[0]?.token_id);

  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [{ nfts, status }, setNfts] = React.useState<any>({
    nfts: zombiesNfts || [],
    status: zombiesNfts ? "success" : "loading",
  });

  const getNfts = async () => {
    if (zombiesNfts) return;
    let myNfts: any = [];
    try {
      /* Get Sold Nfts on Opensea */
      await getOpenseaAssets(ethAddress).then(res => {
        myNfts = res?.data?.assets;
      });
      setNfts((prevState: any) => ({
        ...prevState,
        nfts: myNfts,
        nftsView: myNfts,
        status: "success",
      }));
      setSelected(myNfts?.[0]?.token_id);
    } catch (err) {
      setNfts((prevState: any) => ({ ...prevState, status: "error" }));
    }
  };

  React.useEffect(() => {
    /* Get Nfts on mount */
    getNfts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const doRaise = async () => {
    setIsLoading(true);
    await handleEthereumCall(selectedNft?.token_id, address)
      .then(() => {
        dispatch({
          type: "CHANGE_FIELDS",
          payload: {
            step: 6,
            nftType: "raised",
            selectedToRaise: selected,
            transferTx: null,
          },
        });
        setIsLoading(false);
      })
      .catch(err => {
        toast({
          status: "error",
          title: err?.message || "Error raising your zombie!",
          isClosable: true,
        });

        setIsLoading(false);
      });
  };
  /* Derived States */
  const isEmpty = !nfts || nfts?.length === 0;
  const selectedNft = nfts?.filter((n: any) => n.token_id === selected)?.[0];

  return (
    <>
      <PageLayout scene="3">
        <PageBackButton onClick={() => goToStep(2)} />
        {status !== "success" && (
          <Center pt="5" w="100vw" h="100vh">
            <Stack
              px="4"
              py="4"
              flexDir="column"
              maxW="300px"
              bg="purple.500"
              color="white"
              rounded="md"
              w="100%"
              mx="auto"
              alignItems="center"
              textAlign="center"
            >
              <Text fontSize="lg" fontWeight="500">
                {status === "loading"
                  ? "Getting your zombies..."
                  : "There aren't any Atomic Zombies in this wallet, yet."}
              </Text>
              {status === "loading" && (
                <Spinner
                  size="lg"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="violet.100"
                  color="white"
                />
              )}
            </Stack>
          </Center>
        )}
        {status === "success" && (
          <SimpleGrid
            maxW="1150px"
            w="100%"
            mx="auto"
            columns={{ base: 1, lg: 2 }}
            px="8"
            py="20"
            color="white"
          >
            {/* Nfts Grid */}
            {isEmpty ? (
              <Center textAlign="center">
                <Text>There aren't any Atomic Zombies in this wallet.</Text>
              </Center>
            ) : (
              <Wrap
                order={{ base: 2, lg: 1 }}
                w="100%"
                maxW={{ base: "350px", lg: "500px" }}
                mx="auto"
                columns={{ base: 2, lg: 3 }}
                overflowY="auto"
                maxH={{ base: "400px", lg: "605px" }}
                spacing="6px"
                p="4px"
                justify={{ base: "center", lg: "flex-start" }}
                className="custom-scroll"
              >
                {nfts?.map((nft: any) => (
                  <WrapItem key={nft.id}>
                    <NftBox
                      onClick={() => setSelected(nft.token_id)}
                      isActive={selected === nft.token_id}
                      currentNft={nft}
                    />
                  </WrapItem>
                ))}
              </Wrap>
            )}

            {/* Selection */}

            <Stack
              order={{ base: 1, lg: 2 }}
              w="100%"
              spacing="4"
              maxW="453px"
              mx="auto"
              px={{ base: "0", lg: "4" }}
              pb={{ base: "4", lg: "0" }}
            >
              {/* Selected Nft Image */}
              {isEmpty ? (
                <Center h={{ base: "100px", lg: "200px" }} />
              ) : (
                <Image src={selectedNft?.image_url || demoGif} maxH="500px" objectFit="cover" />
              )}
              {/* Selected Nft Details */}
              <Stack spacing="1">
                <Stack direction="row" align="center">
                  <Text
                    fontWeight="600"
                    fontSize="lg"
                    noOfLines={1}
                    textShadow="1px 1px 2px rgba(0, 0, 0, 1)"
                  >
                    {selectedNft?.name || selectedNft?.token_id}
                  </Text>
                  {selectedNft && (
                    <IconButton
                      size="sm"
                      variant="ghost"
                      colorScheme="violet"
                      border="none"
                      color="white"
                      as={Link}
                      href={selectedNft?.permalink}
                      isExternal
                      aria-label="open link"
                      icon={<RiExternalLinkLine size="20px" />}
                    />
                  )}
                </Stack>
                <Text noOfLines={2} fontSize="sm" textShadow="1px 1px 2px rgba(0, 0, 0, 1)">
                  {selectedNft?.description}
                </Text>
                <Button
                  colorScheme="purple"
                  onClick={doRaise}
                  isLoading={isLoading}
                  isDisabled={!selected}
                >
                  Raise Your Zombie
                </Button>
                <Text pt="1" fontSize="10px" textShadow="1px 1px 2px rgba(0, 0, 0, 0.5)">
                  If you just purchased a Zombie but don't see it here yet, don't worry! Zombies
                  walk slowly and usually take 30s to get to your wallet.
                </Text>
              </Stack>
            </Stack>
          </SimpleGrid>
        )}
      </PageLayout>
    </>
  );
};
