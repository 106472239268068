import React from "react";
// chakra
import { Button, Text, Box, Heading, Center, useToast } from "@chakra-ui/react";
// ui
import { PageLayout } from "components/layouts";
import { PageBackButton } from "components/atoms";
import { connectToEthWallet, connectToEthWalletAndGetZombies, getAddress } from "shared/api";
import { useZombie } from "components/contexts/zombie";

type SceneProps = {
  goToStep: (step: number) => any;
};

export const Two: React.FC<SceneProps> = ({ goToStep }) => {
  const { dispatch }: { dispatch: (args: any) => void } = useZombie();

  /* Hooks */
  const toast = useToast();

  const [isLoading, setIsLoading] = React.useState(false);
  /* Raise */
  const doConnectToEthAndRaise = async () => {
    setIsLoading(true);
    await connectToEthWalletAndGetZombies()
      .then(async res => {
        await getAddress()
          .then(addressRes => {
            if (addressRes.status === 200) {
              /* We're connected to finnie, raise */
              dispatch({
                type: "CHANGE_FIELDS",
                payload: {
                  ethAddress: res?.ethAddress,
                  address: addressRes?.data,
                  zombiesNfts: res?.nfts,
                  step: "raise",
                },
              });
            } else {
              /* We're not connected, go to step 3 */
              dispatch({
                type: "CHANGE_FIELDS",
                payload: {
                  ethAddress: res?.ethAddress,
                  zombiesNfts: res?.nfts,
                  step: 3,
                },
              });
            }
          })
          .catch(() => {
            /* We're not connected, go to step 3 */
            dispatch({
              type: "CHANGE_FIELDS",
              payload: {
                ethAddress: res?.ethAddress,
                zombiesNfts: res?.nfts,
                step: 3,
              },
            });
          });
      })
      .catch(err => {
        if (err?.message === "extension_not_installed") {
          toast({ status: "error", title: "You don't have MetaMask installed", isClosable: true });
        } else if (err?.message === "no_accounts") {
          toast({
            status: "error",
            title: "Please add an account your MetaMask",
            isClosable: true,
          });
        } else if (err?.message === "no_nfts_found") {
          toast({
            status: "error",
            title: <>There aren't any Atomic Zombies in this wallet.</>,
            isClosable: true,
          });
        } else {
          toast({
            status: "error",
            title: "There was an error connecting MetaMask. Please try again.",
            isClosable: true,
          });
        }
        setIsLoading(false);
      });
  };

  /* Buy */
  const doConnectToEthAndBuy = async () => {
    setIsLoading(true);
    await connectToEthWallet()
      .then(async res => {
        setIsLoading(false);
        /* Connected, Go to buy */
        dispatch({
          type: "CHANGE_FIELDS",
          payload: {
            ethAddress: res?.ethAddress,
            step: "buy-eth",
          },
        });
      })
      .catch(err => {
        setIsLoading(false);
        if (err?.message === "extension_not_installed") {
          toast({ status: "error", title: "You don't have MetaMask installed", isClosable: true });
        } else if (err?.message === "no_accounts") {
          toast({
            status: "error",
            title: "Please add an account your MetaMask",
            isClosable: true,
          });
        } else {
          toast({
            status: "error",
            title: "There was an error connecting MetaMask. Please try again.",
            isClosable: true,
          });
        }
      });
  };

  return (
    <>
      <PageLayout scene="3">
        <PageBackButton onClick={() => goToStep(1)} />
        <Box
          pr="4"
          pl={{ base: "6", lg: "14", xl: "24" }}
          pt={{ base: "12", lg: "16" }}
          color="white"
        >
          <Heading fontWeight="400" fontSize={{ base: "5xl", md: "6xl", lg: "70px" }}>
            Raising Zombies
          </Heading>
          <Text fontFamily="display" fontSize={{ base: "2xl", lg: "2xl" }}>
            Dig up your Zombie with ETH
          </Text>
          <Text fontSize="15px" mt="9px" maxW="400px" className="text--shadowed">
            Atomic Zombies on Ethereum are still underground. Bring yours to life by transferring it
            to the Arweave network with Koii's Finnie wallet.
            <Box as="span" d="block" h="10px" />
            Until then, no one knows what style it will possess. Watch as your Zombie's
            characteristics are revealed.
            <Box as="span" d="block" h="10px" />
            <Text as="span" color="green.500">
              1 Zombie = 0.03 ETH.
            </Text>
          </Text>
          <div>
            <Button
              w="240px"
              h="56px"
              mt="36px"
              colorScheme="violet"
              size="lg"
              flex={{ base: "none", lg: "1 1 100%" }}
              fontSize="16px"
              isLoading={isLoading}
              onClick={doConnectToEthAndBuy}
            >
              <Center flexDir="column">
                <span>Dig up your Zombie</span>
                <Text as="span" color="green.500" mt="2px" fontSize="12px">
                  Purchase with ETH
                </Text>
              </Center>
            </Button>
            <Text
              pl={{ base: "0", lg: "0" }}
              fontSize="12px"
              mt="2"
              lineHeight="1.3"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
              maxW="400px"
            >
              Already trapped your Zombie in an ETH wallet?{" "}
              <Text
                as="span"
                d="block"
                role="button"
                textDecor="underline"
                cursor="pointer"
                onClick={doConnectToEthAndRaise}
              >
                Skip ahead to raise from the dead.
              </Text>
            </Text>
          </div>
        </Box>
      </PageLayout>
    </>
  );
};
