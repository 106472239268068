import React from "react";
// chakra
import {
  Button,
  Stack,
  Text,
  Image,
  SimpleGrid,
  Wrap,
  WrapItem,
  Center,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
// ui
import { PageLayout } from "components/layouts";
import { NftBox, PageBackButton } from "components/atoms";
import { ConfirmBuyEthModal } from "components/modals";
// icons
import { BsArrowRepeat } from "react-icons/bs";
// assets
import demoGif from "assets/images/demo.gif";

// mocks + apis
import {
  getAllSoldOpenseaAssets,
  getAllSoldOpenseaAssetsFirst,
  getETHNFTsForSale,
  getEthNftsWithIds,
} from "shared/api";
import { zeroPad } from "shared/utils";

type SceneProps = {
  goToStep: (step: any) => any;
};

export const BuyEth: React.FC<SceneProps> = ({ goToStep }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [{ nfts, nftsView, status }, setNfts] = React.useState<any>({
    nfts: [],
    nftsView: [],
    status: "loading",
  });

  const [selected, setSelected] = React.useState<any>();

  const selectedNft = nfts?.filter((n: any) => n.tokenId === selected)?.[0];

  const getNfts = async () => {
    let soldNfts: any = [];
    let allNfts: any = [];
    let availableNfts: any = [];
    try {
      setNfts((prevState: any) => ({
        ...prevState,
        status: "loading",
      }));
      /* Get Sold Nfts on Opensea */
      await getAllSoldOpenseaAssetsFirst()
        .then(res => {
          soldNfts = res || [];
        })
        .catch(async () => {
          /* Fallback and get from Opensea */
          await getAllSoldOpenseaAssets().then(res => {
            soldNfts = res || [];
          });
        });
      /* Get All Available Nfts */
      await getETHNFTsForSale().then(res => {
        allNfts = res?.data?.data;
      });

      availableNfts = allNfts?.filter?.((n: any) => {
        const isFound = soldNfts?.find((soldNft: any) => soldNft?.token_id === n?.tokenId);
        return isFound ? false : true;
      });

      const availableNftsIds = availableNfts?.map((n: any) => n.tokenId);

      if (availableNftsIds?.length > 0) {
        await getEthNftsWithIds(availableNftsIds)
          .then(res => {
            availableNfts = res?.data?.data;
          })
          .catch(() => {});
      }

      setNfts((prevState: any) => ({
        ...prevState,
        nfts: availableNfts,
        nftsView: availableNfts,
        status: "success",
      }));
      setSelected(availableNfts?.[0]?.tokenId);
    } catch (err) {
      setNfts((prevState: any) => ({ ...prevState, status: "error" }));
    }
  };

  const isEmpty = nftsView?.length === 0;

  React.useEffect(() => {
    /* Get Nfts on mount */
    getNfts();
  }, []);

  return (
    <>
      <ConfirmBuyEthModal selectedToBuy={selected} isOpen={isOpen} onClose={onClose} />
      <PageLayout
        scene="3"
        h={{ base: "unset", lg: "100vh" }}
        minH={{ base: "100vh", lg: "unset" }}
      >
        <PageBackButton onClick={() => goToStep(2)} />
        {status !== "success" || isEmpty ? (
          <Center pt="5" w="100vw" h="100vh">
            <Stack
              px="4"
              py="4"
              flexDir="column"
              maxW="300px"
              bg="purple.500"
              color="white"
              rounded="md"
              w="100%"
              mx="auto"
              alignItems="center"
              textAlign="center"
            >
              <Text fontSize="lg" fontWeight="500">
                {status === "loading"
                  ? "Getting zombies..."
                  : "There aren't any Atomic Zombies left to buy."}
              </Text>
              {status === "loading" && (
                <Spinner
                  size="lg"
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="violet.100"
                  color="white"
                />
              )}
              {isEmpty && status !== "loading" && (
                <Button
                  onClick={getNfts}
                  size="sm"
                  colorScheme="purple"
                  leftIcon={<BsArrowRepeat size="18px" />}
                >
                  Check again
                </Button>
              )}
            </Stack>
          </Center>
        ) : (
          <SimpleGrid
            maxW="1150px"
            w="100%"
            mx="auto"
            columns={{ base: 1, lg: 2 }}
            px={{ base: "4", lg: "8" }}
            py="20"
            color="white"
          >
            {/* Nfts Grid */}
            <Wrap
              order={{ base: 2, lg: 1 }}
              w="100%"
              maxW={{ base: "350px", lg: "500px" }}
              mx="auto"
              columns={{ base: 2, lg: 3 }}
              overflowY="auto"
              maxH={{ base: "400px", lg: "605px" }}
              spacing="2"
              p="4px"
              justify={{ base: "center", lg: "flex-start" }}
              className="custom-scroll"
            >
              {nftsView?.map((nft: any) => (
                <WrapItem key={nft.tokenId}>
                  <NftBox
                    onClick={() => setSelected(nft.tokenId)}
                    isActive={selected === nft.tokenId}
                    currentNft={nft}
                  />
                </WrapItem>
              ))}
            </Wrap>

            {/* Selection */}
            {selectedNft && (
              <Stack
                order={{ base: 1, lg: 2 }}
                w="100%"
                spacing="4"
                maxW="453px"
                mx="auto"
                px={{ base: "0", lg: "4" }}
                pb={{ base: "4", lg: "0" }}
              >
                <Center w="100%" h={{ base: "300px", lg: "450px" }}>
                  {/* Selected Nft Image */}
                  <Image src={demoGif} mx="auto" maxH="100%" />
                </Center>
                {/* Selected Nft Details */}

                <Stack spacing="1">
                  <Stack direction="row" align="center">
                    <Text
                      fontWeight="600"
                      fontSize="lg"
                      noOfLines={1}
                      textShadow="1px 1px 2px rgba(0, 0, 0, 1)"
                    >
                      {selectedNft?.name || selectedNft?.tokenId
                        ? `#${zeroPad(selectedNft?.tokenId)}`
                        : ""}
                    </Text>
                  </Stack>
                  <Text fontSize="sm" textShadow="1px 1px 2px rgba(0, 0, 0, 1)">
                    {selectedNft?.description}
                  </Text>
                  <Button colorScheme="purple" onClick={onOpen} isDisabled={!selected} shadow="lg">
                    Buy This Zombie
                  </Button>
                </Stack>
              </Stack>
            )}
          </SimpleGrid>
        )}
      </PageLayout>
    </>
  );
};
