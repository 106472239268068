import { useZombie } from "components/contexts/zombie";
import React from "react";
// chakra
import {
  Button,
  Stack,
  Text,
  Box,
  Heading,
  Tooltip,
  Center,
  Link,
  useToast,
  Icon,
} from "@chakra-ui/react";
// ui
import { PageLayout } from "components/layouts";
import { PageBackButton } from "components/atoms";
import { connectToExtension, getAddress, initExtension } from "shared/api";
// icons
import { RiQuestionFill } from "react-icons/ri";

type SceneProps = {
  goToStep: (step: number) => any;
};

export const Three: React.FC<SceneProps> = ({ goToStep }) => {
  const toast = useToast();

  const { dispatch }: { dispatch: (args: any) => void } = useZombie();

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  async function doTryToConnect() {
    try {
      setIsLoading(true);
      // Check if extension exists
      await initExtension().catch(() => {
        /* User does not have finnie, go to 8 */
        goToStep(8);
        throw new Error("");
      });
      // Connect to extension
      await connectToExtension();
      // Get the address
      await getAddress().then(res => {
        if (res.status === 200) {
          /* Done, go and raise */
          dispatch({
            type: "CHANGE_FIELDS",
            payload: { address: res?.data, step: "raise" },
          });
        } else {
          toast({
            status: "error",
            title: "There was an error connecting Finnie. Please try again.",
            isClosable: true,
          });
        }
      });
      setIsLoading(false);
    } catch (err) {
      toast({
        status: "error",
        title: "There was an error connecting Finnie. Please try again.",
        isClosable: true,
      });
      setIsLoading(false);
    }
  }

  return (
    <>
      <PageLayout scene="7">
        <PageBackButton onClick={() => goToStep(2)} />
        <Box
          pr="4"
          pl={{ base: "6", lg: "14", xl: "20" }}
          pt={{ base: "12", lg: "16" }}
          color="white"
        >
          <Heading fontWeight="400" fontSize={{ base: "5xl", md: "6xl", lg: "70px" }}>
            Bring me to Life
          </Heading>
          <Text fontFamily="display" fontSize={{ base: "2xl", lg: "2xl" }}>
            Connect your Finnie Wallet
          </Text>
          <Text fontSize="15px" mt="9px" maxW="400px" className="text--shadowed">
            Atomic Zombies on Ethereum are still underground. Bring yours to life by transferring it
            to the Arweave network with Koii's Finnie wallet. <br />
            <br />
            Then, watch as your Zombie's characteristics are revealed. And compete for the ultimate
            feature:{" "}
            <Tooltip
              p="2"
              placement="right-start"
              hasArrow
              bg="purple.500"
              arrowShadowColor="white"
              label="The Zombie with the most attention each day wears the crown for the next 24 hours"
            >
              <Text as="span" textDecor="underline" color="green.500">
                the attention crown.
                <Tooltip
                  p="2"
                  hasArrow
                  bg="purple.500"
                  arrowShadowColor="white"
                  placement="right-start"
                  label="To earn attention rewards, you have to bridge your NFT to Arweave. This requires connecting your Ethereum wallet and your Finnie wallet"
                >
                  <span>
                    <Icon ml="1" color="green.500" as={RiQuestionFill} />
                  </span>
                </Tooltip>
              </Text>
            </Tooltip>
          </Text>

          <Stack
            spacing={{ base: "3", lg: "8" }}
            direction={{ base: "column", lg: "row" }}
            mt="36px"
            w="100%"
            maxW="512px"
          >
            <Button
              h="56px"
              colorScheme="purple"
              size="lg"
              flex={{ base: "none", lg: "1 1 100%" }}
              fontSize="16px"
              onClick={doTryToConnect}
              isLoading={isLoading}
            >
              <Center flexDir="column">
                <span>Give your Zombie Life</span>
                <Text as="span" color="green.500" mt="2px" fontSize="12px">
                  Connect Finnie
                </Text>
              </Center>
            </Button>
            <Button
              h="56px"
              colorScheme="violet"
              size="lg"
              flex={{ base: "none", lg: "1 1 100%" }}
              fontSize="16px"
              as={Link}
              isExternal
              href="https://chrome.google.com/webstore/detail/finnie/cjmkndjhnagcfbpiemnkdpomccnjblmj"
            >
              <Center flexDir="column">
                <span>Need the right spells?</span>
                <Text as="span" color="green.500" mt="2px" fontSize="12px">
                  Get Finnie
                </Text>
              </Center>
            </Button>
          </Stack>
        </Box>
      </PageLayout>
    </>
  );
};
