import React from "react";

const ZombieContext = React.createContext(null);

const actionTypes = {
  changeFields: "CHANGE_FIELDS",
  goNext: "GO_NEXT",
  goPrev: "GO_PREV",
  goToStep: "GO_TO_STEP",
};

const zombieReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.changeFields:
      const { payload } = action;
      return { ...state, ...payload };
    case actionTypes.goToStep:
      return { ...state, step: action?.payload };
    default:
      throw new Error(`No action type found for zombieReducer`);
  }
};

const zombieInitializer = () => {
  return {
    step: 1,
    address: null,
    ethAddress: null,
    selectedToRaise: null,
    nftsToRaise: [],
    nftType: null,
    randomId: null,
    transferTx: null,
  };
};

function ZombieProvider({ children }) {
  const [state, dispatch] = React.useReducer(zombieReducer, null, zombieInitializer);

  return <ZombieContext.Provider value={{ state, dispatch }}>{children}</ZombieContext.Provider>;
}

function useZombie() {
  const context = React.useContext(ZombieContext);

  if (!context) {
    throw new Error(`useYouTubeUpload must be used inside YouTubeUploadProvider`);
  }
  return context;
}

export { ZombieProvider, useZombie };
