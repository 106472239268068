import React from "react";

// chakra
import { Center, Box } from "@chakra-ui/react";
// ui
import { PageToolbar } from "components/atoms";

// css
import "./index.scss";

type PageLayoutProps = {
  children: React.ReactNode;
  scene?: string;
  minH?: any;
  h?: any;
};

const PageLayout: React.FC<PageLayoutProps> = ({ scene, children, minH, h = "100vh" }) => {
  return (
    <Center w="100%" h={h} pos="relative" zIndex="1" minH={minH}>
      <PageToolbar />
      {/* Image Scene Wrapper */}
      <Box w="100%" h="100%" className={`scene scene--${scene || "0"}`}>
        {children}
      </Box>
    </Center>
  );
};

export default PageLayout;
