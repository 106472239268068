import React from "react";
// chakra
import { Button, Stack, Text, Box, Heading, Link, Flex, Center, Tooltip } from "@chakra-ui/react";
// ui
import { PageLayout } from "components/layouts";

type SceneProps = {
  goToStep: (step: number) => any;
};

export const One: React.FC<SceneProps> = ({ goToStep }) => {
  return (
    <>
      <PageLayout scene="1">
        <Flex
          flexDirection="column"
          align={{ base: "center", lg: "flex-start" }}
          pr="4"
          pl={{ base: "6", lg: "44" }}
          pt={{ base: "8", lg: "32" }}
          color="white"
          textAlign={{ base: "center", md: "unset" }}
        >
          <Heading fontWeight="400" fontSize={{ base: "6xl", lg: "90px" }}>
            Atomic Zombies
          </Heading>
          <Text fontFamily="display" fontSize={{ base: "2xl", lg: "4xl" }}>
            by DKleine
          </Text>
          <Text fontSize="15px" mt="9px" maxW="450px" className="text--shadowed">
            10,000 zombies, each with its own set of random, generative characteristics. Watch as
            your zombie's characteristics unlock, emerge, and evolve as they receive more attention
            online, powered by the Koii Network and Proof of Real Traffic.{" "}
            <Box as="span" d="block" h="10px" />
            NFTs as you know them are dead.{" "}
            <Text as="span" color="green.500">
              These NFTs are undead.
            </Text>
            <Box as="span" d="block" h="10px" />
            Choose your zombies wisely. And pay attention. Their lives depend on it.
          </Text>
          <Box w="100%" maxW="450px">
            <Stack
              spacing={{ base: "4", lg: "8" }}
              direction={{ base: "column", lg: "row" }}
              mt="20px"
              w="100%"
              maxW="700px"
            >
              <Tooltip
                p="2"
                hasArrow
                placement="bottom-start"
                arrowShadowColor="white"
                bg="purple.500"
                label={
                  <>
                    <Text>
                      Koii Zombies are super affordable, but can take up to 16 hours to ramble into
                      your wallet with{" "}
                      <Link
                        href="https://koii.network/gradual-consensus.pdf"
                        isExternal
                        textDecor="underline"
                      >
                        gradual consensus.
                      </Link>
                      <Box as="span" d="block" h="5px" />
                      Ethereum Zombies have significant gas fees but will run right to you.
                    </Text>
                  </>
                }
              >
                <Button
                  h="56px"
                  colorScheme="purple"
                  size="lg"
                  flex={{ base: "none", lg: "1 1 100%" }}
                  fontSize="16px"
                  onClick={() => goToStep(4)}
                >
                  <Center flexDir="column">
                    <span>Slow & Smart</span>
                    <Text as="span" color="green.500" mt="2px" fontSize="12px">
                      Koii Zombies
                    </Text>
                  </Center>
                </Button>
              </Tooltip>
              <Button
                h="56px"
                colorScheme="violet"
                size="lg"
                flex={{ base: "none", lg: "1 1 100%" }}
                fontSize="16px"
                onClick={() => goToStep(2)}
              >
                <Center flexDir="column">
                  <span>Fast & Gassed</span>
                  <Text as="span" color="green.500" mt="2px" fontSize="12px">
                    ETH Zombies
                  </Text>
                </Center>
              </Button>
            </Stack>
            <Text
              pl={{ base: "0", lg: "0" }}
              fontSize="12px"
              mt="5px"
              lineHeight="1.2"
              textShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
              maxW="400px"
              sx={{
                "@media (max-width: 770px)": {
                  position: "absolute",
                  bottom: "15px",
                  left: "50%",
                  transform: "translateX(-50%)",
                },
              }}
            >
              These NFTs are revolutionary.{" "}
              <Link isExternal href="https://blog.koii.network/atomiczombies" textDecor="underline">
                Learn more.
              </Link>
            </Text>
          </Box>
        </Flex>
      </PageLayout>
    </>
  );
};
