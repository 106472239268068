import React from "react";
// routes
import Routes from "./Routes";
// styles
import "styles/app.scss";
// ui
import { ThemeLayout } from "components/layouts";
import { ZombieProvider } from "components/contexts/zombie";

function App() {
  return (
    <ThemeLayout>
      <ZombieProvider>
        <Routes />
      </ZombieProvider>
    </ThemeLayout>
  );
}

export default App;
